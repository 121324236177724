import React from "react"
import styled from "styled-components"

const CheckBoxStyled = styled.div`
  position: relative;
  input[type="checkbox"] {
    left: -99999px;
    opacity: 0;
    position: absolute;
  }
  label {
    cursor: pointer;
    line-height: 30px;
    padding-left: 35px;
    vertical-align: middle;
  }
  label::before {
    background: #fff;
    border: 2px solid #999;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    border-radius: 4px;
    height: 30px;
    width: 30px;
    box-sizing: border-box;
  }
  input[type="checkbox"]:focus + label::before,
  input[type="checkbox"].focus + label::before {
    -webkit-box-shadow: 0 0 8px #2375bb;
    -moz-box-shadow: 0 0 8px #2375bb;
    box-shadow: 0 0 8px #2375bb;
    outline: none;
  }
  input[type="checkbox"]:checked + label::before,
  input[type="checkbox"][checked="checked"] + label::before {
    content: "✓";
    color: ${(p) => p.theme.Blue};
    font-size: 35px;
    line-height: 20px;
    padding-top: -10px;
  }
`

const CheckBox = ({ label, name, onClick, className, checked }) => {
  const id = label.replace(/[\W_]+/g, "-").toLowerCase()
  const labelHTML = label ? <label htmlFor={id}>{label}</label> : <label />

  return (
    <CheckBoxStyled>
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        className={className}
        onClick={onClick}
      />{" "}
      {labelHTML}
    </CheckBoxStyled>
  )
}

export default CheckBox
