import React, { useState } from "react"
import { connect } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import ResponsiveSpacing from "../components/responsive-spacing"
import Status from "../components/status"
import Spacer from "../components/spacer"
import Layout from "../components/layout"
import Center from "../components/center"
import SEO from "../components/seo"
import SVG from "../components/svg"
import Input from "../components/inputs"
import CheckBox from "../components/checkbox"
import FormButton from "../components/form-button"
import BottomColorNav from "../components/bottom-color-nav"
import EMAIL_REGEXP from "../utils/email-regexp"
import HOST from "../utils/host"

const errorMsgs = {
  required: "Required",
  email: "E-Mail Address is Invalid",
}

const PricingStyled = styled.div`
  .pricing {
    .prices {
      padding: 10px 5px;
    }

    h2 {
      color: ${(p) => p.theme.Blue};
      font-size: 1.8em;
      text-align: left;
      font-family: "interstate-compressed";
      border-bottom: 2px solid ${(p) => p.theme.Grey};
      padding-bottom: 3px;
      margin-top: 20px;
      text-indent: 5px;
    }

    .line {
      width: 100%;
      font-size: 1.4em;
      padding-bottom: 2px;
      overflow: hidden;
      color: ${(p) => p.theme.Blue};
      background: url("/dot.png") repeat-x center right;
      background-size: 6px;
      font-weight: bold;
    }
    span.text {
      display: inline-block;
      bottom: -4px;
      float: left;
      background: ${(p) => p.theme.White};
      letter-spacing: 1.2px;
      word-wrap: no-wrap;
      padding: 0 3px 3px 0;
    }
    span.dots {
    }
    span.price {
      padding: 0 0 3px 3px;
      display: inline-block;
      bottom: -4px;
      float: right;
      background: ${(p) => p.theme.White};
    }
  }
`

const Pricing = ({ fields }) => {
  return (
    <PricingStyled>
      <div className="pricing">
        <h2
          dangerouslySetInnerHTML={{
            __html: fields.heading,
          }}
        />
        <div className="prices">
          {fields.line.map((line, i) => {
            return (
              <div className="line" key={i}>
                <span className="text">{line.text}</span>
                <span className="price">${line.price}</span>
              </div>
            )
          })}
        </div>
      </div>
    </PricingStyled>
  )
}

const PersonalMessageStyled = styled.div`
  .message-area {
    .option {
      padding: 10px 5px;
    }

    label {
      margin-left: 10px;
      font-size: 1.5em;
      color: ${(p) => p.theme.DarkBlue};
    }

    h2 {
      color: ${(p) => p.theme.Blue};
      font-size: 1.8em;
      text-align: left;
      font-family: "interstate-compressed";
      border-bottom: 2px solid ${(p) => p.theme.Grey};
      padding-bottom: 3px;
      margin-top: 20px;
      text-indent: 5px;
    }

    .input {
      margin-left: 50px;
    }
  }
`

const PersonalMessage = ({
  fields,
  msgError,
  setMSGError,
  setPersonalMsg,
  personalMsg,
}) => {
  return (
    <PersonalMessageStyled>
      <div className="message-area">
        <h2
          dangerouslySetInnerHTML={{
            __html: fields.heading,
          }}
        />
        <div className="option">
          <CheckBox
            label={fields.label}
            name={"wants_message"}
            checked={personalMsg.wants_message}
            onClick={(e) => {
              setPersonalMsg({
                ...personalMsg,
                wants_message: !personalMsg.wants_message,
              })
            }}
          />
        </div>
        <Input
          className="input"
          placeholder={fields.message_placeholder}
          name="message"
          onChange={(e) => {
            const { value } = e.target
            setPersonalMsg({
              ...personalMsg,
              message: value,
            })
          }}
        />
        <div className="error">{msgError}</div>
      </div>
    </PersonalMessageStyled>
  )
}

const ContactFormStyled = styled.form`
  padding: 40px 0;

  .error {
    font-size: 1.4em;
    text-align: center;
    color: ${(p) => p.theme.Red};
  }
`

const CakeOptionsStyled = styled.div`
  .option-area {
    h2 {
      color: ${(p) => p.theme.Blue};
      font-size: 1.8em;
      text-align: left;
      font-family: "interstate-compressed";
      border-bottom: 2px solid ${(p) => p.theme.Grey};
      padding-bottom: 3px;
      margin-top: 20px;
      text-indent: 5px;
    }
    .option {
      padding: 10px 5px;
    }

    label {
      margin-left: 10px;
      font-size: 1.5em;
      color: ${(p) => p.theme.DarkBlue};
    }
  }
`
const PICK_KEY = {
  "PICK 1": 1,
  "PICK 2": 2,
  "PICK 3": 3,
  "PICK 4": 4,
  "PICK ANY": Math.Infinity,
}

const CakeOptions = ({ options, checked, setChecked }) => {
  const selectOption = (name, text, type) => {
    const max = PICK_KEY[type]
    let newChecked = checked
    if (typeof newChecked[name] === "undefined") newChecked[name] = []
    if (checked[name].includes(text)) {
      // Already selected, remove
      newChecked[name] = checked[name].filter((n) => n !== text)
    } else if (checked[name].length + 1 > max) {
      // Too many already selected
      newChecked[name] = checked[name]
      newChecked[name].shift()
      newChecked[name].push(text)
    } else {
      // Not enough selected yet
      newChecked[name].push(text)
    }
    setChecked({
      ...newChecked,
    })
  }

  return (
    <CakeOptionsStyled>
      {options.map((option, i) => {
        return (
          <div className="option-area" key={i}>
            <h2
              dangerouslySetInnerHTML={{
                __html: option.heading,
              }}
            />{" "}
            {option.options.map((o, i) => {
              return (
                <div className="option" key={i}>
                  <CheckBox
                    label={o.text}
                    name={o.text}
                    checked={checked[option.name].includes(o.text) || ""}
                    onClick={(e) => {
                      selectOption(option.name, o.text, option.selection_type)
                    }}
                  />
                </div>
              )
            })}
          </div>
        )
      })}
    </CakeOptionsStyled>
  )
}

const PERSONAL_MSG_DEFAULT = {
  wants_message: false,
  message: "",
}

const ContactForm = ({ dispatch, fields }) => {
  const [status, setStatus] = useState("")
  const [loading, setLoading] = useState(false)
  const [selectionError, setSelectionError] = useState("")
  const [personalMsg, setPersonalMsg] = useState(PERSONAL_MSG_DEFAULT)
  const [msgError, setMSGError] = useState("")

  let optionCats = {}
  if (fields.cake_options.length > 0)
    fields.cake_options
      .map((o) => o.name)
      .map((name) => (optionCats[name] = []))

  const [checked, setChecked] = useState(optionCats)

  const { register, handleSubmit, errors } = useForm()

  const required = (name) => {
    if (!errors[name]) return
    if (errors[name].message) return errors[name].message
    const type = errors[name].type
    if (errorMsgs[type]) return errorMsgs[type]
  }

  const validateCakeSelectionsOnSubmit = (checked) => {
    let errors = []
    fields.cake_options.map(({ name, selection_type }) => {
      const max = PICK_KEY[selection_type]
      if (selection_type !== "PICK ANY") {
        // All require at least 1 except PICK ANY.
        const choosen = checked[name]
        const length = choosen.length
        if (length == 0 || length > max) errors.push(name)
      }
    })
    return errors.length === 0
  }

  const onSubmit = (data) => {
    setLoading(true)
    // Validate Personalized message.
    if (personalMsg.wants_message) {
      data.wants_message = personalMsg.wants_message
      data.message = personalMsg.message
      if (!data.message) {
        setMSGError("Please include a message if you want one.")
        setLoading(false)
        return
      } else {
        setMSGError("")
      }
    }
    // Validate sections
    if (validateCakeSelectionsOnSubmit(checked)) {
      data.cake_options = checked
    } else {
      setSelectionError(fields.cake_options_error_message)
      setLoading(false)
      return
    }

    var opts = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }

    fetch(HOST + "/order-cake", opts)
      .then((raw) => raw.json())
      .then((res) => {
        setLoading(false)
        setStatus({ code: 1, msg: fields.form_submitted_successfully_message })
      })
      .catch((err) => {
        setLoading(false)
        setStatus({ code: 0, msg: err })
        console.log(err)
      })
  }

  return (
    <ContactFormStyled onSubmit={handleSubmit(onSubmit)}>
      <Input
        placeholder="Name"
        name="name"
        error={required("name")}
        register={register}
        registerValue={{
          required: "Required",
        }}
      />
      <Input
        type="email"
        name="email"
        placeholder="E-mail"
        error={required("email")}
        register={register}
        registerValue={{
          required: "Required",
          pattern: {
            value: EMAIL_REGEXP,
            message: errorMsgs.email,
          },
        }}
      />
      <Input
        placeholder="Phone"
        name="phone"
        error={required("phone")}
        register={register}
        registerValue={{
          required: "Required",
        }}
      />
      <Input
        placeholder="Desired Pick-up Date"
        name="desired_pick_up_date"
        error={required("desired_pick_up_date")}
        register={register}
        registerValue={{
          required: "Required",
        }}
      />
      <Input
        placeholder="Occasion"
        name="occasion"
        error={required("occasion")}
        register={register}
        registerValue={{
          required: "Required",
        }}
      />
      <CakeOptions
        options={fields.cake_options}
        setChecked={setChecked}
        checked={checked}
      />
      <PersonalMessage
        msgError={msgError}
        setMSGError={setMSGError}
        setPersonalMsg={setPersonalMsg}
        personalMsg={personalMsg}
        fields={fields.personal_message}
      />
      <Pricing fields={fields.pricing} />
      <div className="error">{selectionError}</div>
      <Center>
        <Status status={status} setStatus={setStatus} />
        <Spacer height="20px" />
        <FormButton loading={loading} width="250px">
          {loading ? "PROCESSING" : fields.button_label}
        </FormButton>
      </Center>
    </ContactFormStyled>
  )
}

const OrderACakeStyled = styled.div`
  max-width: 820px;
  margin: 123px auto 150px auto;

  @media (max-width: 768px) {
    margin: 123px auto 50px auto;
    padding: 0 10px;
  }

  h1 {
    text-align: center;
    color: ${(props) => props.theme.DarkBlue};
    margin: 0;
    text-transform: uppercase;
    font-size: 9.2em;
    font-weight: 800;
    font-family: "interstate-compressed";
    @media (max-width: 768px) {
      font-size: 6.1em;
    }
  }

  h2 {
    text-align: center;
    color: ${(props) => props.theme.Blue};
    margin: 0;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 6em;
    font-family: "interstate-compressed";

    @media (max-width: 768px) {
      font-size: 4em;
    }
  }

  p {
    color: ${(props) => props.theme.DarkBlue};
    max-width: 580px;
    margin: 20px auto;
    text-align: center;
  }

  a {
    color: ${(props) => props.theme.DarkBlue};
  }
`

const OrderACake = ({ fields }) => {
  const { headline, statement, wave_color, sub_heading } = fields
  return (
    <OrderACakeStyled>
      <ResponsiveSpacing>
        <h1
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: headline,
          }}
        />
        <Center margin="0 0 10px 0">
          <SVG name="SWave" color={wave_color} />
        </Center>
        <h2
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: sub_heading,
          }}
        />
        <p
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: statement,
          }}
        />
        <ContactForm fields={fields} />
      </ResponsiveSpacing>
    </OrderACakeStyled>
  )
}

const ConnectedOrderACake = connect((state) => state)(OrderACake)

const OrderACakePage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "order-a-cake-page" } }) {
        edges {
          node {
            acf {
              headline
              wave_color
              sub_heading
              statement
              search_title
              search_description
              share_headline
              share_comment
              cake_options_error_message
              form_submitted_successfully_message
              cake_options {
                heading
                options {
                  text
                }
                name
                selection_type
              }
              personal_message {
                heading
                label
                message_placeholder
              }
              pricing {
                heading
                line {
                  text
                  price
                }
              }
              button_label
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node.acf
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields
  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <ConnectedOrderACake fields={fields} />
      <BottomColorNav />
    </Layout>
  )
}

export default OrderACakePage
